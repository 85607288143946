// Feature Names for the feature flags
export const Features = {
  AI_CHATBOT: 'fflag.ai.chatbot',
  COBALT_MAP_ALERTS: 'fflag.cobalt.mapAlerts',
  COBALT_MONTHLY_ASIN_LIMITS: 'fflag.cobalt.monthlyAsinLimits',
  COBALT_TOLKIEN_PHASE_1: 'fflag.cobalt.tolkien.phase1',
  COBALT_SHARE_AND_TRENDS_ASIN_LIMITS_PHASE_2:
    'fflag.cobalt.shareTrends.ASINLimits.phase2',
  COMPETITIVE_INTELLIGENCE_ADDONS: 'fflag.competitiveIntelligence.addOns',
  COMPETITIVE_INTELLIGENCE_DEMO_SEGMENT:
    'fflag.competitiveIntelligence.demoSegment',
  COMPETITIVE_INTELLIGENCE_CHART_BRUSH:
    'fflag.competitiveIntelligence.chartBrush',
  COMPETITIVE_INTELLIGENCE_MVP: 'fflag.competitiveIntelligence.mvp',
  COMPETITIVE_INTELLIGENCE_AD_CAMPAIGNS:
    'fflag.competitiveIntelligence.adCampaigns',
  COMPETITIVE_INTELLIGENCE_PRODUCT_SLIDEOUT_RANK_PERFORMANCE:
    'fflag.competitiveIntelligence.slideout.rankPerformance',
  COMPETITIVE_INTELLIGENCE_RANK_CHANGE_DISTRIBUTION:
    'fflag.competitiveIntelligence.keywordsChangeDistribution',
  COMPETITIVE_INTELLIGENCE_AD_OPPORTUNITIES:
    'fflag.competitiveIntelligence.adOpportunities',
  COMPETITIVE_INTELLIGENCE_UPGRADE_INCENTIVE:
    'fflag.competitiveIntelligence.upgradeIncentive',
  COMPETITIVE_INTELLIGENCE_VALIDATION:
    'fflag.competitiveIntelligence.validation',
  KEYWORDS_SHARE_OF_VOICE: 'fflag.keywords.shareOfVoice',
  KEYWORDS_UI_REFRESH: 'fflag.keywords.uiRefresh',
  KEYWORD_SCOUT_DISTRIBUTION: 'fflag.keywords.keywordsDistribution',
  KEYWORD_SCOUT_BADGE_COLUMN: 'fflag.keywords.ksBadgeColumn',
  MONEY_BACK_GUARANTEE: 'fflag.account.moneyBackGuarantee',
  OPPORTUNITY_FINDER_TABLE_SPARKLINE: 'fflag.opportunityFinder.sparkline',
  PROFIT_OVERVIEW_V2: 'fflag.salesAnalytics.profitOverview.v2',
  SELLER_FEATURES_DISABLED: 'fflag.sellerFeatures.disabled',
  SELLER_FEATURES_MAINTENANCE: 'fflag.sellerFeatures.maintenance',
  LISTING_BUILDER_DISABLE_SYNC: 'fflag.listingBuilder.disableSync',
  REVIEW_AUTOMATION_REVIEWS_CHART: 'fflag.reviewAutomation.reviewsChart',
  PPC_UPDATE_BANNERS: 'fflag.account.ppcUpdatePermissionsBanner'
}
