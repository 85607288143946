export const UNLIMITED_ACCESS_LIMIT = -1
export const NO_ACCESS_LIMIT = 0
export const INDEFINITE_TIMEFRAME = 'indefinite'

export const UPGRADE_LIMIT_MODAL = 'UpgradeLimitModal'

export const LIMIT_KEYS = {
  aiFeatures: 'aiFeatures',
  aiFeaturesChatRequests: 'aiFeaturesChatRequests',
  competitiveIntelligenceMarkets: 'competitiveIntelligenceMarkets',
  competitiveIntelligenceMarketItems: 'competitiveIntelligenceMarketItems',
  competitiveIntelligenceMarketKeywords:
    'competitiveIntelligenceMarketKeywords',
  fbaReimbursement: 'fbaReimbursement',
  supplierDatabase: 'supplierDatabase',
  supplierTracker: 'supplierTracker'
}
